export default {
  en: {
    title: '',

  },
  pl: {
    title: "FAQ",
    faqs: [
      {
        question: "Kiedy i gdzie odbywa się hackathon?",
        answer: `<p>Hackathon potrwa 48 godzin. Wydarzenie odbędzie się <br/> 27-29 czerwca w Gdańsku</p>`
      }, {
        question: "Co muszę zrobić żeby wziąć udział w hackathonie?",
        answer: `<p>Żeby wziąć udział w hackathonie musisz zapisać się uzupełniając krótki formularz na stronie <a href='https://evenea.pl/pl/wydarzenie/hack4change2025'>https://evenea.pl/pl/wydarzenie/hack4change2025</a> Na Hackathonie należy mieć swój laptop. Ilość miejsc jest ograniczona więc nie zostawiaj zgłoszenia na ostatnią chwilę! Jeśli jesteś niepełnoletni, ale masz 16 lat lub więcej - przynieś <a href="https://drive.google.com/file/d/1mMpdL1FSkazokBK0NEifEBTrwiq2Jgk2/view?usp=drive_link">zgodę</a> podpisaną przez opiekuna prawnego.</p>`
      }, {
        question: "Jak się zapisać?",
        answer: `<p>Nie prowadzimy selekcji, o udziale w wydarzeniu decyduje kolejność zgłoszeń.<br/>
Wydarzenie odbędzie się w formule BYOL (Bring Your Own Laptop).<br/>
</p>`
      }, {
        question: "Czy hackathon jest tylko dla programistów?",
        answer: `<p>Nie! Uważamy że najciekawsze projekty tworzą zróżnicowane grupy, dlatego do udziału w hackathonie zachęcamy także specjalistów innych dziedzin: grafików, innowatorów, naukowców i wszystkich tych, którzy chcą zmieniać świat na lepsze.</p>`
      },
      // {
      //   question: "Ile osób może wziąć udział w hackathonie?",
      //   answer: `<p>W hackathonie weźmie udział 150 osób.</p>`
      // },
      {
        question: "Jak duże będą grupy?",
        answer: `<p>Grupy będą liczyć maksymalnie 5 osób, dzięki temu współpraca będzie łatwa i każdy uczestnik będzie miał realny wpływ na kształt projektu.</p>`
      }, {
        question: "Jakie będą projekty?",
        answer: `<p>Twój projekt musi wpisywać się w 3 kryteria wyznaczone przez Marka Kamińskiego: Człowiek i jego dobrostan, Otwarte Dane i Smart Cities. Co to znaczy? To znaczy, że musi być to projekt odpowiadający na jakiś problem człowieka i jego otoczenia, który rozwiążesz za pomocą technologii! W ramach każdej ze ścieżek zaproponujemy konkretne tematy, których może dotyczyć Twój projekt. Możesz skupić się na jednym z nich lub połączyć kilka - decyzja należy do Ciebie. Jeżeli masz własny pomysł, który wpisuje się w ideę hackathonu - nic nie stoi na przeszkodzie by go zrealizować.</p>`
      }, {
        question: "Jak będą tworzone grupy?",
        answer: `<p>Zespoły będą tworzone na początku wydarzenia. Możesz zapisać się na hackathon razem z przyjaciółmi i stworzyć zespół, możesz poszukać zespołu z ciekawym pomysłem, do którego mógłbyś dołączyć lub znaleźć chętnych do współpracy podczas realizacji własnego pomysłu.</p>`
      }, {
        question: "Czy w czasie hackathonu mogę zmienić grupy lub pracować samodzielnie?",
        answer: `<p>Możesz zmienić grupę jeśli taka będzie decyzja wszystkich jej członków. Jeżeli wolisz pracować w pojedynkę - nic nie stoi na przeszkodzie, ale zachęcamy do wspólnego działania, w końcu co dwie głowy to nie jedna, a co dopiero cztery!</p>`
      }, {
        question: "Czy mogę należeć do więcej niż jednej grupy?",
        answer: `<p>Na pracę nad prototypami uczestnicy będą mieć tylko 48h. To niewiele nawet na zrealizowanie jednego pomysłu, a praca w kilku grupach, choć nie jest niemożliwa, to na pewno nie byłaby komfortowa. Zachęcamy więc, aby skupić się na jednej grupie i jednym projekcie.</p>`
      }, {
        question: "Jak będzie wyglądać prezentacja projektów i kto będzie je oceniał?",
        answer: `<p>Na prezentację swojego prototypu każda z grup będzie miała 3 minuty, przez kolejne 2 minuty członkowie grupy będą odpowiadać na pytania jury. Po wszystkich prezentacjach projekty będą oceniane przez jury składające się z organizatorów i partnerów strategicznych hackathonu oraz przez samych uczestników, którzy przyznają nagrodę publiczności.</p>`
      }, {
        question: "Jakie będą nagrody?",
        answer: `<p>Nagrody nie są najważniejsze, ale zapewniamy - warto się starać!</p>`
      }, {
        question: "Jak wziąć udział w warsztatach przed hackathonem?",
        answer: `<p>Warsztaty odbędą się przed hackathonem w godzinach popołudniowych i są dostępne tylko dla uczestników hackathonu. Szczegóły oraz instrukcję dołączenia do warsztatów prześlemy na kilka dni przed ich startem.</p>`
      },
      
    ]
  }
}